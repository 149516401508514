<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-if="!isNew && !link"
        cols="12"
      >
        <i class="fas fa-spin fa-spinner"></i>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <material-card
          color="primary"
          :heading="pageTitle"
        >
          <v-card-text>
            <i
              v-if="!isNew && !link"
              class="fas fa-spin fa-spinner"
            ></i>
            <validation-observer v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(submit)">
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.label').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.label"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.label')"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.homepage_icon').toLowerCase()"
                    >
                      <DropzoneComponent
                        v-model="form.pictures"
                        :aria-errormessage="errors"
                        :label="$t('dropzone.label_singular')"
                        :max-files="1"
                        :field-label="$t('forms.homepage_icon')"
                        no-crop
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{}"
                      rules="required"
                      :name="$t('forms.type').toLowerCase()"
                    >
                      <v-radio-group
                        v-model="form.linkable_type"
                        row
                      >
                        <v-radio
                          label="Rubrique"
                          value="App\Models\Section"
                        />
                        <v-radio
                          label="Page"
                          value="App\Models\Page"
                        />
                      </v-radio-group>
                    </validation-provider>
                    <template v-if="form.linkable_type === 'App\\Models\\Section'">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('forms.section').toLowerCase()"
                      >
                        <vue-select
                          v-model="form.linkable_id"
                          :options="lists && lists.sections"
                          :reduce="section => section.id"
                        >
                          <template v-slot:option="option">
                            <span v-html="option.option_label"></span>
                          </template>
                        </vue-select>
                      </validation-provider>
                    </template>
                    <template v-if="form.linkable_type === 'App\\Models\\Page'">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('forms.page').toLowerCase()"
                      >
                        <v-select
                          v-model="form.linkable_id"
                          :items="formattedPagesList"
                          outlined
                          item-text="label"
                          item-value="id"
                          :error-messages="errors"
                          :label="$t('forms.page')"
                        />
                      </validation-provider>
                    </template>
                  </v-col>
                </v-row>
                <div class="full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/quick-links/quick-links"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    v-if="!isNew"
                    color="error"
                    @click="initiateLinkDeletion"
                  >
                    {{ $t('delete') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDeletionDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deleteLink"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  // import DropzoneComponent from '../../components/Dropzone';
  // import SectionTreeView from '../../components/SectionTreeView';
  import serverError from '../../mixins/serverError';
  export default {
    name: 'QuickLinkForm',

    components: {},

    mixins: [serverError],

    data: () => ({
      form: {
        label: null,
        picture_id: null,
        linkable_type: null,
        linkable_id: null,
        pictures: [],
      },
      showDeletionDialog: false,
    }),

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
        link: 'quicklinks/getLink',
        lists: 'quicklinks/getLists',
      }),
      isNew() {
        return !this.$route.params.id;
      },
      pageTitle() {
        return this.isNew ? this.$t('pageTitles.NewQuickLink') : this.$t('pageTitles.Editing') + this.link.label;
      },
      formattedPagesList() {
        if (!this.lists || !this.lists.pages || !Object.keys(this.lists.pages).length) {
          return [];
        }
        return Object.keys(this.lists.pages).reduce((acc, item) => {
          acc.push({ header: item });
          this.lists.pages[item]
            .sort((a, b) => a.label < b.label ? -1 : 1)
            .map(page => acc.push(page));
          return acc;
        }, []);
      },
    },

    watch: {
      'form.linkable_type'(newValue, oldValue) {
        if (oldValue) {
          this.form.linkable_id = null;
        }
      },
    },

    created() {
      this.$store.dispatch('quicklinks/fetchLists');
      if (!this.isNew) {
        this.$store.dispatch('quicklinks/fetchLink', this.$route.params.id)
          .then(() => {
            this.form = {
              ...this.link,
              pictures: this.link.picture ? [this.link.picture] : [],
            };
          });
      }
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { id, label, pictures, linkable_id, linkable_type } = this.form
          const picture_id = !pictures || !pictures[0] || !pictures[0].id ? null : pictures[0].id;
          const payload = { id, label, linkable_id, linkable_type, picture_id };
          const action = this.isNew ? 'quicklinks/createLink' : 'quicklinks/updateLink';
          const message = this.isNew ? this.$t('successfully_created') : this.$t('successfully_updated');
          this.$store.dispatch(action, payload)
            .then(() => {
              this.$toasted.success(message);
              this.$router.push('/quick-links/quick-links');
            })
            .catch(error => this.displayErrors(error));
        }
      },
      initiateLinkDeletion() {
        this.showDeletionDialog = true;
      },
      closeDeletionDialog() {
        this.showDeletionDialog = false;
      },
      deleteLink() {
        this.$store.dispatch('quicklinks/deleteLink', this.$route.params.id)
          .then(() => {
            this.$toasted.success(this.$t('item_deleted'));
            this.$router.push('/quick-links/quick-links');
          })
          .catch(error => this.displayErrors(error))
          .finally(() => this.closeDeletionDialog());
      },
    },
  }
</script>
