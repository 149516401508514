var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-forms-view","fluid":"","tag":"section"}},[_c('v-row',[(!_vm.isNew && !_vm.link)?_c('v-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"fas fa-spin fa-spinner"})]):_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{attrs:{"color":"primary","heading":_vm.pageTitle}},[_c('v-card-text',[(!_vm.isNew && !_vm.link)?_c('i',{staticClass:"fas fa-spin fa-spinner"}):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.label').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.label')},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.homepage_icon').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropzoneComponent',{attrs:{"aria-errormessage":errors,"label":_vm.$t('dropzone.label_singular'),"max-files":1,"field-label":_vm.$t('forms.homepage_icon'),"no-crop":""},model:{value:(_vm.form.pictures),callback:function ($$v) {_vm.$set(_vm.form, "pictures", $$v)},expression:"form.pictures"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.type').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.form.linkable_type),callback:function ($$v) {_vm.$set(_vm.form, "linkable_type", $$v)},expression:"form.linkable_type"}},[_c('v-radio',{attrs:{"label":"Rubrique","value":"App\\Models\\Section"}}),_c('v-radio',{attrs:{"label":"Page","value":"App\\Models\\Page"}})],1)]}}],null,true)}),(_vm.form.linkable_type === 'App\\Models\\Section')?[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.section').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-select',{attrs:{"options":_vm.lists && _vm.lists.sections,"reduce":function (section) { return section.id; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',{domProps:{"innerHTML":_vm._s(option.option_label)}})]}}],null,true),model:{value:(_vm.form.linkable_id),callback:function ($$v) {_vm.$set(_vm.form, "linkable_id", $$v)},expression:"form.linkable_id"}})]}}],null,true)})]:_vm._e(),(_vm.form.linkable_type === 'App\\Models\\Page')?[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.page').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.formattedPagesList,"outlined":"","item-text":"label","item-value":"id","error-messages":errors,"label":_vm.$t('forms.page')},model:{value:(_vm.form.linkable_id),callback:function ($$v) {_vm.$set(_vm.form, "linkable_id", $$v)},expression:"form.linkable_id"}})]}}],null,true)})]:_vm._e()],2)],1),_c('div',{staticClass:"full-width d-flex justify-space-between align-center"},[_c('v-btn',{attrs:{"color":"gray","to":"/quick-links/quick-links"}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),(!_vm.isNew)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.initiateLinkDeletion}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")]):_vm._e(),_c('v-btn',{attrs:{"type":"submit","color":_vm.processing ? 'gray' : 'success'}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.processing ? 'fas fa-spinner fa-spin' : 'fas fa-check')}}),_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")],1)],1)],1)]}}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.showDeletionDialog),callback:function ($$v) {_vm.showDeletionDialog=$$v},expression:"showDeletionDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" "+_vm._s(_vm.$t('delete_confirm'))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":_vm.closeDeletionDialog}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteLink}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }